const initialState = {
  isDarkMode: false,
  region: 'IN',
  detectedRegion: null,
  regionInitStatus: null,
  regionRedirectDone: false,
  pageRegionDetected: false,
  fetchingIpRegion: false,
}

export const TOGGLE_DARKMODE = 'TOGGLE_DARKMODE'
export const SET_DARKMODE = 'SET_DARKMODE'
export const SET_REGION = 'SET_REGION'
export const GET_IP_REGION = 'GET_IP_REGION'
export const GET_IP_REGION_SUCCESS = 'GET_IP_REGION_SUCCESS'
export const GET_IP_REGION_FAILURE = 'GET_IP_REGION_FAILURE'
export const SET_PAGE_REGION = 'SET_PAGE_REGION'
export const SET_DETECTED_REGION = 'SET_DETECTED_REGION'
export const REDIRECTED_TO_REGION = 'REDIRECTED_TO_REGION'
export const SET_REGION_INIT_STATE = 'SET_REGION_INIT_STATE'

export const toggleDarkMode = isDarkMode => ({
  type: TOGGLE_DARKMODE,
  isDarkMode,
})

export const setDarkMode = mode => ({
  type: SET_DARKMODE,
  mode,
})

export const setRegion = region => ({
  type: SET_REGION,
  region,
})

export const setPageRegion = region => ({
  type: SET_PAGE_REGION,
  region,
})

export const getIpRegion = () => ({
  type: GET_IP_REGION,
})

export const getIpRegionSuccess = region => ({
  type: GET_IP_REGION_SUCCESS,
  region,
})

export const getIpRegionFailure = () => ({
  type: GET_IP_REGION_FAILURE,
})

export const setDetectedRegion = region => ({
  type: SET_DETECTED_REGION,
  region,
})

export const redirectedToRegion = () => ({
  type: REDIRECTED_TO_REGION,
})

export const setRegionInitState = status => ({
  type: SET_REGION_INIT_STATE,
  status,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DARKMODE:
      return { ...state, isDarkMode: action.isDarkMode }
    case SET_DARKMODE:
      return { ...state, isDarkMode: action.mode }
    case SET_REGION:
      return { ...state, region: action.region }
    case GET_IP_REGION:
      return { ...state, fetchingIpRegion: true }
    case GET_IP_REGION_SUCCESS:
      return { ...state, fetchingIpRegion: false }
    case GET_IP_REGION_FAILURE:
      return { ...state, fetchingIpRegion: false }
    case SET_PAGE_REGION:
      return { ...state, pageRegionDetected: true, region: action.region }
    case SET_DETECTED_REGION:
      return { ...state, detectedRegion: action.region }
    case REDIRECTED_TO_REGION:
      return { ...state, regionRedirectDone: true }
    case SET_REGION_INIT_STATE:
      return { ...state, regionInitStatus: action.status }
    default:
      return state
  }
}

export default reducer
