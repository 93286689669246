import { call, put, takeLatest } from 'redux-saga/effects'
import api from '../../utils/api'
import { getUUID } from '../../utils/uuid'
import * as Sentry from "@sentry/gatsby";

import {
  FREE_SIGNUP_START,
  FREE_SIGNUP_FAILURE,
  FREE_SIGNUP_SUCCESS,
} from '../freeSignup'

const FREE_SIGNUP_URL = '/provision_account/'

function* provisionFreemiumAccount(payload) {
  const formData = payload.formData
  payload.formData.x_request_id = getUUID()
  const {password, ...maskedData} = payload.formData

  if(window && window.getGclid && window.getGclid()) {
		formData['gclid'] = window.getGclid()
	}

  const request = () => api.post(FREE_SIGNUP_URL, formData)
  try {
    console.log("Provisioning Freemium Account")

    Sentry.withScope(function(scope) {
      scope.setExtra('API Request', maskedData)
      scope.setTag('x_requst_id', maskedData.x_request_id)
      scope.setTag("email", maskedData.business_email);
      scope.setTag("greythr-API", FREE_SIGNUP_URL);
      Sentry.captureMessage(`Saving Regisration and Provisioning Freemium Account. :${maskedData.business_email}`)
    });

    const response = yield call(request)
    let result_message = ''
    if (response.data.status === "success") {
      if (window && formData.business_email && process.env.GATSBY_ENABLE_AC_SITE_TRACKING === 'true'){
        window.track_ac_contact(formData.business_email)
      }
      yield put({ type: FREE_SIGNUP_SUCCESS, redirectUrl: response.data.redirect_url, registrationId:  response.data.registration_id })
      result_message = `Freemium Account Provisioning Success. :${maskedData.business_email}`
    } else {
      yield put({
        type: FREE_SIGNUP_FAILURE, errorMessage: response.data.errors, formData: formData })
        result_message = `Freemium Account Provisioning Failed. :${maskedData.business_email}`
    }
    
    Sentry.withScope(function(scope) {
      scope.setExtra('API Request', maskedData)
      scope.setTag("email", maskedData.business_email);
      scope.setTag('x_requst_id', maskedData.x_request_id)
      scope.setTag("greythr-API", FREE_SIGNUP_URL);
      if(response?.data){
        scope.setExtra('API Response', JSON.stringify(response?.data))
      }
      Sentry.captureMessage(result_message)
    });

  } catch (error) {
    yield put({ type: FREE_SIGNUP_FAILURE, errorMessage: error, formData: formData })
    
    Sentry.withScope(function(scope) {
      scope.setTag("greythr-API", FREE_SIGNUP_URL);
      scope.setTag("email", maskedData.business_email);
      scope.setTag('x_requst_id', maskedData.x_request_id)
      scope.setExtra('API Request', maskedData)
      if(error?.response?.data){
        scope.setExtra('API Response', JSON.stringify(error?.response?.data))
      }
      Sentry.captureException(error)
      Sentry.captureMessage(`Freemium Account Provisioning Failed with Error. :${maskedData.business_email}`)
    });
    console.log(`Freemium Account Provisioning Failed with Error:${error}`)
  }
}

function* freeSignupSaga() {
  yield takeLatest(FREE_SIGNUP_START, provisionFreemiumAccount)
}

export default freeSignupSaga
