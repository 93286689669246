const primary = {
  primary100: "#fce6ff",
  primary200: "#f3a3ff",
  primary300: "#e97aff",
  primary400: "#dc52ff",
  primary500: "#c728f7",
  primaryMain: "#AA00EA",
  primary700: "#8900c4",
  primary800: "#69009e",
  primary900: "#4c0078",
  primary1000: "#310052",
};
const secondaryBlue = {
  secondaryBlue100: "#e6ffff",
  secondaryBlue200: "#a3faff",
  secondaryBlue300: "#7af4ff",
  secondaryBlue400: "#52ebff",
  secondaryBlue500: "#29dfff",
  secondaryBlueMain: "#00c8f7",
  secondaryBlue700: "#00a4d1",
  secondaryBlue800: "#0080ab",
  secondaryBlue900: "#005f85",
  secondaryBlue1000: "#00405e",
};
const secondaryOrange = {
  secondaryOrange100: "#fff8e6",
  secondaryOrange200: "#ffe3a3",
  secondaryOrange300: "#ffd37a",
  secondaryOrange400: "#ffbf52",
  secondaryOrange500: "#ffa929",
  secondaryOrangeMain: "#ff9100",
  secondaryOrange700: "#d97400",
  secondaryOrange800: "#b35900",
  secondaryOrange900: "#8c4100",
  secondaryOrange1000: "#662c00",
};
const secondaryGreen = {
  secondaryGreen100: "#EEFAF4",
  secondaryGreen200: "#D9FBEA",
  secondaryGreen300: "#B7E8D1",
  secondaryGreen400: "#96DDBC",
  secondaryGreen500: "#76D3A8",
  secondaryGreenMain: "#58C995",
  secondaryGreen700: "#4BAB7F",
  secondaryGreen800: "#3E8F6A",
  secondaryGreen900: "#327355",
  secondaryGreen1000: "#285A43",
};
const error = {
  error100: "#feeeee",
  error200: "#fbd6d6",
  error300: "#f9b5b5",
  error400: "#f69393",
  error500: "#f37373",
  errorMain: "#f05454",
  error700: "#cc4747",
  error800: "#aa3c3c",
  error900: "#893030",
  error1000: "#6c2626",
};
const success = {
  success100: "#edf6ed",
  success200: "#d3ead4",
  success300: "#b0dab2",
  success400: "#8cc88f",
  success500: "#69b86d",
  successMain: "#48a84d",
  success700: "#3d8f41",
  success800: "#337737",
  success900: "#29602c",
  success1000: "#204c23",
};
const warning = {
  warning100: "#fef7ec",
  warning200: "#fcecd1",
  warning300: "#f9ddac",
  warning400: "#f7ce86",
  warning500: "#f4bf62",
  warningMain: "#f2b13f",
  warning700: "#ce9636",
  warning800: "#ac7e2d",
  warning900: "#8a6524",
  warning1000: "#6d501c",
};
const neutral = {
  neutral200: "#E8E8E9",
  neutral300: "#D2D1D2",
  neutral400: "#BBBBBC",
  neutral500: "#A4A4A5",
  neutral600: "#8D8D8F",
  neutral700: "#777679",
  neutral800: "#605F62",
  neutral900: "#49494C",
  neutral1000: "#333235",
  white: "#ffffff",
  black: "#1C1B1F",
};

const colors = {
  ...primary,
  ...secondaryBlue,
  ...secondaryOrange,
  ...secondaryGreen,
  ...error,
  ...success,
  ...warning,
  ...neutral,
};

const keys = {
  primary: Object.keys(primary),
  secondaryBlue: Object.keys(secondaryBlue),
  secondaryOrange: Object.keys(secondaryOrange),
  secondaryGreen: Object.keys(secondaryGreen),
  error: Object.keys(error),
  success: Object.keys(success),
  warning: Object.keys(warning),
  neutral: Object.keys(neutral),
};

export default colors;

export const {
  primary: primaryKeys,
  secondaryBlue: secondaryBlueKeys,
  secondaryOrange: secondaryOrangeKeys,
  secondaryGreen: secondaryGreenKeys,
  error: errorKeys,
  success: successKeys,
  warning: warningKeys,
  neutral: neutralKeys,
} = keys;
