import { colors, fontWeights } from "../index";

const typography = {
  h1: {
    color: colors.black,
    fontFamily: "Poppins",
    fontSize: ["2.375rem","2.375rem", "3.188rem", "3.188rem"],
    fontStyle: "normal",
    fontWeight: fontWeights.semiBold,
    lineHeight: ["2.85rem", "2.85rem", "3.825rem", "3.825rem"],
  },
  h2: {
    color: colors.black,
    fontFamily: "Poppins",
    fontSize: ["1.75rem", "1.75rem", "2.375rem", "2.375rem"],
    fontStyle: "normal",
    fontWeight: [fontWeights.medium, fontWeights.medium, fontWeights.semiBold, fontWeights.semiBold],
    lineHeight: ["2.1rem", "2.1rem", "2.85rem", "2.85rem"],
  },
  h3: {
    color: colors.black,
    fontFamily: "Poppins",
    fontSize: ["1.313rem", "1.313rem", "1.75rem", "1.75rem"],
    fontStyle: "normal",
    fontWeight: fontWeights.medium,
    lineHeight: ["1.575rem", "1.575rem", "2.1rem", "2.1rem"],
  },
  h4: {
    color: colors.black,
    fontFamily: "Poppins",
    fontSize: "1.313rem",
    fontStyle: "normal",
    fontWeight: fontWeights.regular,
    lineHeight: "1.575rem",
  },
  h5: {
    color: colors.black,
    fontFamily: "Poppins",
    fontSize: ["1rem", "1rem", "1.313rem", "1.313rem"],
    fontStyle: "normal",
    fontWeight: fontWeights.semiBold,
    lineHeight: ["1.2rem", "1.2rem", "1.5rem", "1.5rem"],
  },
  h6: {
    color: colors.black,
    fontFamily: "Poppins",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: fontWeights.semiBold,
    lineHeight: "1.2rem",
  },
  h7: {
    color: colors.black,
    fontFamily: "Poppins",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: fontWeights.regular,
    lineHeight: "1.2rem",
  },
  textLg400: {
    color: colors.black,
    fontFamily: "Open Sans",
    fontSize: ["1rem", "1rem", "1.313rem", "1.313rem"],
    fontStyle: "normal",
    fontWeight: fontWeights.regular,
    lineHeight: ["1.5rem", "1.5rem", "1.969rem", "1.969rem"],
  },
  textLg500: {
    color: colors.black,
    fontFamily: "Open Sans",
    fontSize: "1.313rem",
    fontStyle: "normal",
    fontWeight: fontWeights.medium,
    lineHeight: "1.969rem",
  },
  textMd400: {
    color: colors.black,
    fontFamily: "Open Sans",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: fontWeights.regular,
    lineHeight: "1.5rem",
  },
  textMd500: {
    color: colors.black,
    fontFamily: "Open Sans",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: fontWeights.medium,
    lineHeight: "1.5rem",
  },
  textSm: {
    color: colors.black,
    fontFamily: "Open Sans",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: fontWeights.medium,
    lineHeight: "1.125rem",
  },
  textXs: {
    color: colors.black,
    fontFamily: "Open Sans",
    fontSize: "0.563rem",
    fontStyle: "normal",
    fontWeight: fontWeights.medium,
    lineHeight: "0.844rem",
  },
};

export default typography;
