import { tailwind } from '@theme-ui/presets';
import { colors, fontWeights, typography } from "../styles/theme";
import { theme2 } from "./index-v1";

const theme = {
	colors,
	fontWeights,
	typography,
	breakpoints: ["300px", "600px", "900px", "1280px"],
	styles : {
		...tailwind.styles,
		h1: {
			mt: "4.5rem",
			mb: "1.5rem",
			...typography.h1,
		},
		h2: {
			mt: ["3rem", "3rem", "4.5rem", "4.5rem"],
			mb: "1.5rem",
			...typography.h2
		},
		h3: {
			mt: "3rem",
			mb: "1.5rem",
			...typography.h3
		},
		h4: {
			mt: "3rem",
			mb: "1.5rem",
			...typography.h4,
		},
		h5: {
			mt: "3rem",
			mb: "1.5rem",
			...typography.h5,
		},
		h6: {
			...typography.h6,
		},
		p: {
			mb: "1.5rem",
			mt: "1.5rem",
			...typography.textMd400,
			whiteSpace: 'pre-wrap',
			wordWrap: 'break-word',
		},
		span: {
			mb: "1.5rem",
			mt: 0,
			...typography.textMd400,
			whiteSpace: 'pre-wrap',
			wordWrap: 'break-word',
		},
		pre: {
			mb: "1.5rem",
			...typography.textMd400,
			whiteSpace: 'pre-wrap',
			wordWrap: 'break-word',
		},
		a: {
			...typography.textMd400,
			textDecoration: 'none',
			color: colors.primaryMain,
			'&: hover': {
				textDecoration: 'underline'
			}
		},
		ul: {
			pl: 3,
			mt: "0.25rem",
			mb: "0.25rem",
		},
		li: {
			mt: "0",
			mb: "0.25rem",
			...typography.textMd400,
			p: {
				mb: 0
			}
		},
		ol: {
			pl: 3,
			mt: "0",
			mb: "0.25rem",
		},
		hr: {
			m: 0,
			pb: 2,
		},
		blockquote: {
			borderColor: colors.primaryMain,
			borderStyle: 'solid',
			borderWidth: 0,
			borderLeftWidth: 5,
			pt: 3,
			pb: 1,
			my: 3,
			pl: 4,
		},
	},
};

// MARK: Remove theme2 once all resource files are created
export default {...theme2, ...theme};
