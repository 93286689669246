export const FREE_SIGNUP_START = 'FREE_SIGNUP_START'
export const FREE_SIGNUP_SUCCESS = 'FREE_SIGNUP_SUCCESS'
export const FREE_SIGNUP_FAILURE = 'FREE_SIGNUP_FAILURE'

const initialState = {
  freeSignupState: FREE_SIGNUP_START,
  domainUrl: 'https://hr.greytip.com',
  errorMessage: '',
  formData: {},
}

export const freeSignupStart = formData => ({
  type: FREE_SIGNUP_START,
  formData,
})

const freeSignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case FREE_SIGNUP_START:
      return { ...state, freeSignupState: FREE_SIGNUP_START }
    case FREE_SIGNUP_SUCCESS:
      return { ...state, freeSignupState: FREE_SIGNUP_SUCCESS, domainUrl: action.redirectUrl, registrationId: action.registrationId }
    case FREE_SIGNUP_FAILURE:
      return { ...state, freeSignupState: FREE_SIGNUP_FAILURE, errorMessage: action.errorMessage, formData: action.formData }
    default:
      return state
  }
}

export default freeSignupReducer
