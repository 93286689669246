import { tailwind } from '@theme-ui/presets'
import { toTheme } from '@theme-ui/typography'
import altonTheme from 'typography-theme-alton'
import { merge } from 'lodash'
import { colors, typography } from '../styles/theme'

altonTheme.baseFontSize = '10px';

// const typography = toTheme(altonTheme)

const theme = {
	...tailwind,
	colors: {
		...tailwind.colors,
		primary: '#00A1F2',
		secondary2: '#333333',
		coprimary: '#FFF',
		blueLine: '#27ABFC',
		primary2: '#2393ba',
		primaryLight: '#addef7',
		primaryExtraLight: '#d6eef9',
		headerBackground: '#F8F8F8',
		footerBackground: '#F5F8FA',
		footerTitle: '#333',
		subFooterBackground: '#EDEEEF',
		linkColor: '#00A1F2',
		navLinkHighlight: '#00A1F2',
		navLinkColor: '#777',
		lightbg: '#F6FAFF',
		defaultbg: '#fff',
		muted2: '#757575',
		muted3: '#999',
		names: '#00277f',
		brightRow: '#EDF9FE',
		lightGrey: '#f7f7f7',
		orange1: '#ED7A28',
		orange2: '#F17A28',
		accent: '#F17A28',
		accentLight: '#fcbe94',
		accentExtraLight: '#fceade',
		freePlanText: '#37465A',
		freePlanLink: '#0091bc',
		freePlanBg: '#383838',
		academyOrange: '#ea7a26',
		academyBlue: '#088FBB',
		secondaryLight: '#F4F2F3',
		
	},
	styles: {
		...tailwind.styles,
		p: {
			mt: "1.5rem",
			mb: "1.5rem",
			...typography.textMd400,
		},
		li: {
			mt: "0",
			mb: "0.25rem",
			...typography.textMd400,
		},
		h1: {
			mt: "4.5rem",
			mb: "1.5rem",
			...typography.h1,
		},
		h2: {
			mt: ["3rem", "3rem", "4.5rem", "4.5rem"],
			mb: "1.5rem",
			...typography.h2
		},
		h3: {
			mt: "3rem",
			mb: "1.5rem",
			...typography.h3
		},
		h4: {
			mt: "3rem",
			mb: "1.5rem",
			...typography.h4,
		},
		h5: {
			mt: "3rem",
			mb: "1.5rem",
			...typography.h5,
		},
		h6: {
			...typography.h6,
		},
		hr: {
			m: 0,
			pb: 2,
		},
		a: {
			...typography.textMd400,
			textDecoration: 'none',
			color: colors.primaryMain,
			'&: hover': {
				textDecoration: 'underline'
			}
		},
		ul: {
			pl: '1.5rem',
			mt: "0",
			mb: "0.25rem",
		},
		ol: {
			pl: '1.5rem',
			mt: "0",
			mb: "0.25rem",
		},
		pre: {
			fontSize: 3,
			whiteSpace: 'pre-wrap',
			wordWrap: 'break-word',
			color: 'red',
		},
		blockquote: {
			borderColor: colors.primaryMain,
			borderStyle: 'solid',
			borderWidth: 0,
			borderLeftWidth: 5,
			pt: 3,
			pb: 1,
			my: 3,
			pl: 4,
		},
		flex: {
			formbackgroundcolor: {
				backgroundImage: 'linear-gradient(72deg, #1e97fa, #29b6f6)',
			},
		},
	},
	fontSizes: [
		'1rem',
		'1.1rem',
		'1.25rem',
		'1.5rem',
		'1.75rem',
		'2.0rem',
		'2.20rem',
		'3rem',
		'4rem',
		'4.5rem',
	],
	fontWeights: {
		hairline: '200',
		thin: '200',
		light: '300',
		normal: '400',
		medium: '500',
		semibold: '600',
		bold: '700',
		extrabold: '800',
		black: '800',
		body: '400',
		heading: '600',
	},
	fonts: {
		sans: 'Assistant, -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
		serif: 'Georgia, Cambria, "Times New Roman", Times, serif',
		mono: 'Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
		body: 'Assistant, -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
		heading: 'inherit',
		monospace:
			'Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
	},
	sizes: {
		...tailwind.sizes,
	},
	layout: {
		...tailwind.layout,
	},
	text: {
		...tailwind.text,
		caps: {
			textTransform: 'uppercase',
			letterSpacing: '0.2em',
		},
		heading: {
			fontFamily: 'heading',
			fontWeight: 'normal',
			lineHeight: 'heading',
			fontSize: 6,
		},
		normalHeading1: {
			fontFamily: 'heading',
			fontWeight: 'normal',
			fontSize: 5,
			lineHeight: 'heading',
			my: 2,
		},
		lightHeading: {
			fontFamily: 'heading',
			fontWeight: 'normal',
			fontSize: 3,
			lineHeight: 'heading',
			mb: 3,
		},
		extraLightHeading: {
			fontFamily: 'heading',
			fontWeight: 'light',
			fontSize: 3,
			lineHeight: 'heading',
		},
		lightText: {
			fontFamily: 'heading',
			fontWeight: 'light',
			lineHeight: 'heading',
			fontSize: 2,
		},
		extrabold: {
			fontFamily: 'heading',
			fontWeight: 'extrabold',
			fontSize: 7,
			lineHeight: 'heading',
		},
		moduleH1: {
			fontFamily: 'heading',
			fontWeight: 'normal',
			fontSize: [3, 4],
			lineHeight: 'heading',
			textTransform: 'uppercase',
		},
		moduleH2: {
			fontFamily: 'heading',
			fontWeight: 'normal',
			fontSize: [6, 6, 6, 7],
			lineHeight: 'heading',
			color: 'primary',
			fontKerning: 'tight',
		},
		newmoduleH1: {
			fontWeight: 'bold',
			lineHeight: ['1.797rem', '2.246rem'],
			fontSize: ['1.563rem', '1.953rem'],
		},
		newmoduleH2: {
			fontWeight: '500',
			lineHeight: ['2.149rem', '3.204rem'],
			fontSize: ['1.953rem', '3.052rem'],
			color: '#ea7926',
		},
		featureH2: {
			fontFamily: 'heading',
			fontWeight: 'normal',
			fontSize: 4,
			lineHeight: 'heading',
			color: 'secondary2',
			fontKerning: 'tight',
			marginBottom: 3,
		},
		normal: {
			fontSize: 1,
			fontWeight: 'normal',
			color: 'text',
			mb: 3,
		},
		quote: {
			fontSize: 2,
			fontWeight: 'light',
			color: 'text',
			fontFamily: 'serif',
			mb: 3,
		},
		heroText: {
			fontSize: '1.4rem',
			fontWeight: 'light',
			lineHeight: '2rem',
			pb: 3,
		},
		homeH2: {
			fontSize: '2rem',
			fontWeight: 'normal',
			lineHeight: '2rem',
		},
		accordionHeading: {
			fontSize: '1rem',
			fontWeight: 'semibold',
			marginBottom: 0,
			paddingBottom: 0,
		},
		accordionBody: {
			fontSize: '0.95rem',
			fontWeight: 'light',
			fontColor: 'muted2',
			margin: 0,
			marginBottom: 2,
		},
		OnlineTrainingHeader: {
			width: '15%',
			fontFamily: 'heading',
			fontWeight: 'light',
			lineHeight: 'heading',
			color: 'white',
			fontSize: 2,
		},
		OnlineTrainingTitle: {
			p: 2,
			fontFamily: 'heading',
			fontWeight: 'normal',
			lineHeight: 'heading',
			fontSize: 2,
		},
		errorMessage: {
			color: 'red',
			textAlign: 'center',
			fontSize: '1rem',
		},
		successMessage: {
			color: 'green',
			textAlign: 'center',
			fontSize: '1rem',
		},
		pillarHeading1: {
			my: 3,
			fontFamily: 'heading',
			fontWeight: 'light',
			lineHeight: 'heading',
			color: 'white',
			fontSize: 7,
			textShadow: '2px 2px 5px #333',
		},
		pillarHeading2: {
			color: 'primary',
			mt: 4,
			fontSize: 4,
		},
		wikiHeading1: {
			my: 3,
			fontFamily: 'heading',
			fontWeight: 'light',
			lineHeight: 'heading',
			color: 'primary',
			fontSize: 5,
		},
		wikiHeading2: {
			my: 3,
			fontFamily: 'heading',
			fontWeight: 'light',
			lineHeight: 'heading',
			fontSize: 4,
		},
		wikiHeading3: {
			my: 3,
			fontFamily: 'heading',
			fontWeight: 'light',
			lineHeight: 'heading',
			fontSize: 3,
		},
		wikiHeading4: {
			my: 2,
			fontFamily: 'heading',
			fontWeight: 'light',
			lineHeight: 'heading',
			fontSize: 2,
		},
		boxHeading2: {
			my: 3,
			fontFamily: 'heading',
			fontWeight: 'light',
			lineHeight: 'heading',
			fontSize: 4,
			'& a': {
				textDecoration: 'none',
			},
		},
		heroH1: {
			fontWeight: 'normal',
			fontSize: ['1.625rem', '3.433rem'],
			lineHeight: ['31.2px', '57.68px'],
			color: 'orange1',
		},
		heroDesc: {
			fontWeight: ['normal', 'semibold'],
			fontSize: ['1rem', '1.125rem'],
			lineHeight: ['26px', '30.6px'],
			color: '#383938',
			my: 3,
		},
	},
	buttons: {
		...tailwind.buttons,
		primary: {
			color: 'background',
			bg: 'primary',
			borderRadius: '2px',
			'&:hover': {
				bg: 'primary',
				cursor: 'pointer',
			},
			'&:focus': {
				outline: 'none',
			},
		},
		secondary: {
			color: 'primary',
			bg: 'background',
			border: 'solid 2px',
			borderRadius: 0,
			borderColor: 'primary',
			'&:hover': {
				cursor: 'pointer',
				boxShadow: 'inset 0 0 0 3px',
			},
			'&:focus': {
				outline: 'none',
			},
		},
		header: {
			color: 'background',
			bg: 'primary',
			height: '24px',
			py: 0,
			m: 0,
			fontWeight: '0.8rem',
			borderRadius: '2px',
			'&:hover': {
				boxShadow: '0 2px 4px 0 #b5b4b4',
				bg: 'primary',
				cursor: 'pointer',
			},
			'&:focus': {
				outline: 'none',
			},
		},
		borderBottom: {
			borderRadius: '0px',
			borderBottom: 'solid 5px',
			borderBottomColor: 'primary',
			'&:hover': {
				borderBottom: 'solid 5px',
				borderBottomColor: 'defaultbg',
				cursor: 'pointer',
			},
			'&:focus': {
				outline: 'none',
				borderBottom: 'solid 5px',
			},
		},
		primaryBorder: {
			p: '12px',
			width: '100%',
			background: 'transparent',
			borderRadius: '2px',
			border: '2px solid #fff',
			'&:hover': {
				cursor: 'pointer',
				boxShadow: 'inset 0 0 0 3px',
			},
			'&:focus': {
				outline: 'none',
			},
		},
		noBorder: {
			borderRadius: '0px',
			'&:hover': {
				cursor: 'pointer',
			},
			'&:focus': {
				outline: 'none',
			},
		},
		plain: {
			borderRadius: '0px',
			bg: 'defaultbg',
			color: 'footerTitle',
			p: 0,
			pt: 3,
			mx: 2,
			borderBottom: 'solid 1px',
			borderBottomColor: 'lightbg',
			'&:hover': {
				borderBottom: 'solid 1px',
				cursor: 'pointer',
			},
			'&:focus': {
				outline: 'none',
			},
		},
		plain2: {
			borderRadius: '0px',
			bg: 'defaultbg',
			color: 'footerTitle',
			p: 0,
			pt: 3,
			mx: 2,
			'&:hover': {
				cursor: 'pointer',
			},
			'&:focus': {
				outline: 'none',
			},
		},
	},
	cards: {
		primary: {
			textAlign: 'center',
			p: 4,
			m: 2,
			boxShadow: '0 2px 2px 0 #d9eaff',
			width: 260,
			height: 145,
			lineHeight: 6,
			backgroundColor: 'defaultbg',
		},
		default: {
			textAlign: 'center',
			p: 4,
			mx: 2,
			my: 5,
			boxShadow: '0 2px 15px 0 #d9eaff',
			width: 260,
			backgroundColor: 'defaultbg',
		},
		defaultnovalue: {
			textAlign: 'center',
			p: 4,
			mx: 5,
			boxShadow: '0 2px 15px 0 #d9eaff',
			width: '100%',
			backgroundColor: 'defaultbg',
		},
		compact: {
			textAlign: 'center',
			p: 4,
			m: 2,
			boxShadow: '0 2px 2px 0 #d9eaff',
			padding: 1,
			width: 120,
			lineHeight: 3,
			color: 'primary',
			'&:hover': {
				color: 'footerTitle',
				boxShadow: '0 4px 4px 0 #d9eaff',
				cursor: 'pointer',
			},
		},
		large: {
			textAlign: 'center',
			p: 3,
			m: 2,
			boxShadow: '0 2px 2px 0 #d9eaff',
			width: ['100%', '75%', '65%', '55%'],
		},
		testimonial: {
			textAlign: 'jusiy',
			border: 0,
			p: 3,
			boxShadow: '0 1px #d9eaff',
			'&:hover': {
				boxShadow: '0 5px #419BF9',
			},
		},
		table: {
			width: '100%',
			height: '100%',
			boxShadow: '0 0px 4px 0 #f2f2f2',
		},
	},
	links: {
		bold: {
			fontWeight: 'bold',
		},
		primary: {
			fontWeight: 'bold',
			color: 'inherit',
			textDecoration: 'none',
		},
		header: {
			fontWeight: 'normal',
			color: 'blue.5',
			textDecoration: 'none',
		},
	},
	forms: {
		label: {
			fontSize: 1,
			my: 1,
		},
		input: {
			color: '#555',
			border: 'none',
			borderRadius: 1,
			mb: 3,
			fontSize: 'small',
			'&:focus': {
				borderColor: 'primary',
				boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
				outline: 'none',
			},
			'::-webkit-input-placeholder': {
				color: '#999999',
				fontSize: 'small',
			},
			'::-moz-placeholder': {
				/* Firefox 19+ */ color: '#999999',
				fontSize: 'small',
			},
		},
		inputSecondary: {
			color: '#555',
			border: 'none',
			borderRadius: 1,
			border: '1px solid #ccc',
			mt: 3,
			width: '100%',
			fontSize: 'small',
			'&:focus': {
				borderColor: '#66afe9',
				outline: 'none',
			},
			'::-webkit-input-placeholder': {
				color: '#999999',
				fontSize: 'small',
			},
			'::-moz-placeholder': {
				/* Firefox 19+ */ color: '#999999',
				fontSize: 'small',
			},
		},
		borderBottonInput: {
			border: 0,
			borderRadius: 0,
			borderBottom: '1px solid #ccc',
			'&:focus': {
				border: 0,
				borderRadius: 0,
				borderBottom: '1px solid #ccc',
				outline: 'none',
			},
		},
		borderBottonInput: {
			border: 0,
			borderRadius: 0,
			borderBottom: '1px solid #ccc',
			'&:focus': {
				border: 0,
				borderRadius: 0,
				borderBottom: '1px solid #ccc',
				outline: 'none',
			},
		},
		select: {
			color: '#555',
			border: 'none',
			borderRadius: 1,
			backgroundColor: 'white',
			fontSize: 'small',
			'&:focus': {
				borderColor: 'primary',
				boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
				outline: 'none',
			},
		},
		textarea: {
			color: 'black',
			border: 'none',
			borderRadius: 1,
			mb: 3,
			fontFamily: 'sans',
			'&:focus': {
				borderColor: 'primary',
				boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
				outline: 'none',
			},
			'::-webkit-input-placeholder': {
				color: '#999999',
				fontSize: 'small',
				fontFamily: 'sans',
			},
			'::-moz-placeholder': {
				/* Firefox 19+ */ color: '#999999',
				fontSize: 'small',
				fontFamily: 'sans',
			},
		},
	},
	hrmscolorbar: {
		paddingLeft: '15px',
		borderLeft: '5px solid #ee7a35',
		margin: '40px 0',
	},
	hrmscolorbarsmall: {
		paddingLeft: '15px',
		borderLeft: '5px solid #ee7a35',
		marginTop: '40px',
		marginBottom: '20px',
	},
	dividerhrms: {
		height: '10px',
		backgroundColor: '#ee7a35',
		textAlign: 'center !important',
		margin: 'auto',
		marginTop: 'auto',
		width: '7%',
		marginTop: '50px',
	},
	dividerhrmsthin: {
		borderBottom: '2px solid #ee7a35',
		margin: 'auto',
		marginTop: 'auto',
		marginBottom: 'auto',
		width: '20%',
		marginTop: '-6px',
		marginBottom: '50px',
	},
	divider: {
		height: '10px',
		backgroundColor: '#ee7a35',
		textAlign: 'center !important',
		margin: 'auto',
		marginTop: 'auto',
		Width: '50px',
		marginTop: '50px',
		borderRadius: '10px',
	},
	dividerthin: {
		borderBottom: '2px solid #ee7a35',
		margin: 'auto',
		marginTop: 'auto',
		marginBottom: 'auto',
		minWidth: '180px',
		marginTop: '-6px',
		marginBottom: '50px',
		borderRadius: '10px',
	},
	formfieldstyle: {
		justifySelf: 'center',
		border: '1px solid #777',
		borderRadius: '0',
		backgroundColor: '#fff',
		color: 'black',
		my: 1,
		'&:active': {
			boxShadow: 'unset',
			border: '0',
			borderBottom: '1px solid #777',
			backgroundColor: '#fff',
			color: 'black',
		},
		'&:focus': {
			boxShadow: 'unset',
			border: '0',
			borderBottom: '1px solid #777',
			backgroundColor: '#fff',
			color: 'black',
		},
	},
};

const theme2 = merge(typography, theme)
// Updating below style from tailwind style as this is getting overriden 
// the style from Golbal html style we set on reset.js. This is happening after uprading
// @emotion/react to latest(11.11.1). This is to fix the style issue.
// This is doen as part of upgrading from gatby v4 to v5
theme2.styles.root.fontSize = '1rem'
theme2.styles.root.lineHeight = 'normal'
export  {theme2}
