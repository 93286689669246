export { wrapRootElement } from './src/state/reduxWrapper';
import "@fontsource/open-sans";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/poppins";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

export const onRouteUpdate = () => {
  if (window) {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'optimize.activate' });
    }
    //Below lines are for AC site tracking.
    if (window.vgo) {
      window.vgo('update');
    }

    //Below lines of codes are for calling generic gatsby route changed event handler which is inserted to SPA 
    //Through GTM tag "Gatsby Route Changed Event Handler".
    if (window.gatsbyRouteChangedEventHandler) {
      window.gatsbyRouteChangedEventHandler();
    }
    
  }
};
