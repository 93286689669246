import { call, put, takeLatest } from 'redux-saga/effects'
import Cookies from 'universal-cookie'

import {
  GET_IP_REGION,
  GET_IP_REGION_SUCCESS,
  GET_IP_REGION_FAILURE,
  SET_DETECTED_REGION,
  SET_REGION,
  SET_REGION_INIT_STATE,
  SET_PAGE_REGION,
} from '../app'
import { isSupportedRegion, DEFAULT_REGION } from '../../utils/region'
import api from '../../utils/api'

const GET_IP_URL = '/country_code/'
const cookies = new Cookies()

function* getIpRegionSaga(payload) {
  const request = () => api.get(GET_IP_URL)
  try {
    const response = yield call(request)
    const ipRegion = response.data.countryCode
    const effectiveRegion = isSupportedRegion(ipRegion) ? ipRegion : DEFAULT_REGION

    yield put({ type: SET_DETECTED_REGION, region: ipRegion })
    yield put({ type: SET_REGION, region: effectiveRegion })
    yield put({ type: GET_IP_REGION_SUCCESS })
    yield put({ type: SET_REGION_INIT_STATE, status: 'DONE' })

    // console.log(`setting detectedRegion cookie ${ipRegion}`)
    cookies.set('detectedRegion', ipRegion, { path: '/' })
  } catch (error) {
    console.log(error.message)
    yield put({ type: GET_IP_REGION_FAILURE, message: error.message })
  }
}

function* setRegionSaga(payload) {
  // console.log(`setting region cookie ${payload.region}`)
  cookies.set('region', payload.region, { path: '/' })
  yield
}

function* setPageRegionSaga(payload) {
  console.log(`setting page region cookie ${payload.region}`)
  cookies.set('region', payload.region, { path: '/' })
  yield
}

function* regionSaga() {
  yield takeLatest(GET_IP_REGION, getIpRegionSaga)
  yield takeLatest(SET_REGION, setRegionSaga)
  yield takeLatest(SET_PAGE_REGION, setPageRegionSaga)
}

export default regionSaga
