export const SIGNUP_START = 'SIGNUP_START'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'

const initialState = {
  signupState: SIGNUP_START,
  domainUrl: 'https://hr.greytip.com',
  errorMessage: '',
}

export const signupStart = formData => ({
  type: SIGNUP_START,
  formData,
})

const signupReducer = (state = initialState, action) => {
  // console.log(`Singup Reducer action type: ${action.type}`)
  switch (action.type) {
    case SIGNUP_START:
      return { ...state, signupState: SIGNUP_START }
    case SIGNUP_SUCCESS:
      return { ...state, signupState: SIGNUP_SUCCESS, domainUrl: action.redirectUrl }
    case SIGNUP_FAILURE:
      return { ...state, signupState: SIGNUP_FAILURE, errorMessage: action.errorMessage }
    default:
      return state
  }
}

export default signupReducer
