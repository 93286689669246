import { useCookies } from "react-cookie";

const pushFormSubmissionEventToDataLayer = ({
  form_id,
  step_id,
  region_code,
  form_state,
}) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "form_submission_event",
      form_id,
      step_id,
      region_code,
      form_state,
    });
  }
};

export const pushVisitorType = (companyURL) => {
  var company_url = companyURL;
  if (!company_url) {
    [{ company_url }] = useCookies();
  }
  const visitory_type = company_url ? "customer" : "visitor";
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      visitor_type: visitory_type,
    });
    console.log("Visitor Type:", visitory_type);
  }
};

export const starterPlanFinish = ({ freeSignupState, formData }) => {
  const {
    registration_source,
    domain_prefix,
    region_id,
    gc_name,
    gc_source,
    gc_index,
  } = formData;
  console.log(
    "Starter plan details:",
    `${registration_source}, ${domain_prefix}, ${region_id}`
  );
  console.log("GTM cta", { gc_name, gc_source, gc_index });

  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "starter_plan_finish",
      freeSignupState,
      domain_prefix,
      registration_source,
      region_id,
      gc_name,
      gc_source,
      gc_index,
    });
  }
};

export const pushTrialSignupEventData = ({
  step_id,
  region_code,
  form_state,
}) => {
  pushFormSubmissionEventToDataLayer({
    form_id: "WF_TRIAL_SIGNUP",
    step_id,
    region_code,
    form_state,
  });
};

export const pushDemoFormEventData = ({
  form_id,
  step_id,
  region_code,
  form_state,
  gc_name,
  gc_source,
}) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "form_submission_event",
      form_id,
      step_id,
      region_code,
      form_state,
      gc_name,
      gc_source,
    });
  }
};

export const pushProductInterestFormEventData = ({
  form_id,
  step_id,
  region_code,
  form_state,
  gc_name,
  gc_source,
}) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "form_submission_event",
      form_id,
      step_id,
      region_code,
      form_state,
      gc_name,
      gc_source,
    });
  }
};

export const pushCTAClickEventToDataLayer = ({ gc_name, gc_source }) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "button_click_event",
      gc_name,
      gc_source,
    });
  }
};
