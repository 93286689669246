import { call, put, takeLatest,select } from 'redux-saga/effects'
import api from '../../utils/api'
import { getUUID } from '../../utils/uuid'
import { pushTrialSignupEventData } from '../../utils/push-gtm-variable';
import {
  SIGNUP_START,
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
} from '../signup'
import * as Sentry from "@sentry/gatsby";

const SIGNUP_URL = '/save-registration-form/'
export const getRegionCode = (state) => state.app.region;

function* saveRegistrationForm(payload) {
  payload.formData.x_request_id = getUUID()
  const {password, ...maskedData} = payload.formData

  if(window && window.getGclid && window.getGclid()) {
		payload.formData['gclid'] = window.getGclid()
	}

  const request = () => api.post(SIGNUP_URL, payload.formData)

  try {
    
    Sentry.withScope(function(scope) {
      scope.setExtra('API Request', maskedData)
      scope.setTag('x_requst_id', maskedData.x_request_id)
      scope.setTag('verification_code', maskedData.verification_code)
      scope.setTag("greythr-API", SIGNUP_URL);
      Sentry.captureMessage(`Saving Registration and Provisiong Trial Account. :${maskedData.verification_code}`)
    });

    const response = yield call(request)
    let result_message = ''
    const regionCode = yield select(getRegionCode);

    if (response.data.result.status === "success") {
      pushTrialSignupEventData({step_id:'final_step',region_code:regionCode,form_state:'SUCCESS'})
      yield put({ type: SIGNUP_SUCCESS, redirectUrl: response.data.result.redirect_url })
      result_message = `Trial Account Provisioning Success. :${maskedData.verification_code}`
    } else {
      pushTrialSignupEventData({step_id:'final_step',region_code:regionCode,form_state:'FAILED'})
      yield put({ type: SIGNUP_FAILURE, errorMessage: response.data.result.message })
      result_message = `Trial Account Provisioning Failed. :${maskedData.verification_code}`
    }

    Sentry.withScope(function(scope) {
      scope.setExtra('API Request', maskedData)      
      scope.setTag("greythr-API", SIGNUP_URL);
      scope.setTag('x_requst_id', maskedData.x_request_id)
      scope.setTag('verification_code', maskedData.verification_code)
      if(response?.data){
        scope.setExtra('API Response', JSON.stringify(response?.data))
      }
      Sentry.captureMessage(result_message)
    });

  } catch (error) {
    yield put({ type: SIGNUP_FAILURE, errorMessage: error })

    Sentry.withScope(function(scope) {
      scope.setExtra('API Request', maskedData)
      scope.setTag('x_requst_id', maskedData.x_request_id)
      scope.setTag('verification_code', maskedData.verification_code)
      scope.setTag("greythr-API", SIGNUP_URL);
      if(error?.response?.data){
        scope.setExtra('API Response', JSON.stringify(error?.response?.data))
      }
      Sentry.captureException(error)
      Sentry.captureMessage(`Trial Account Provisioning Failed with Error. :${maskedData.verification_code}`)
    });
  }
}

function* signupSaga() {
  yield takeLatest(SIGNUP_START, saveRegistrationForm)
}

export default signupSaga
