/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { createStore, compose, applyMiddleware } from 'redux'
import { Provider, useSelector, useDispatch } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import 'regenerator-runtime/runtime'
import { useCookies, CookiesProvider } from 'react-cookie'
import rootReducer from '.'
import { setRegion, setDetectedRegion, setRegionInitState, getIpRegion } from './app'
import root from './sagas'
import { pushVisitorType } from '../utils/push-gtm-variable'
import { isSupportedRegion, getAlternateRegionCode,getUrlRegionCode } from '../utils/region'

const initStore = () => {
  const windowGlobal = typeof window !== 'undefined' && window
  const sagaMiddleware = createSagaMiddleware()

  const devtools =
    process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension
      ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f

  const store = createStore(rootReducer, compose(applyMiddleware(sagaMiddleware), devtools))

  sagaMiddleware.run(root)
  return store
}

const setupRegion = () => {
  let [{ region, detectedRegion }] = useCookies()
  const dispatch = useDispatch()

  const currentURLRegion = getUrlRegionCode(window.location)
  console.log('greytHR SITE REGION:',region)
  console.log('greytHR SITE DEDUCTED REGION:',detectedRegion)
  console.log('greytHR SITE CURRENT URL REGION:',currentURLRegion)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const regionInitStatus = useSelector(state => state.app.regionInitStatus)
  
  //commented below fix did for changing the site region based the first visted URL.
  //This fix causeing the problem in /signup page refresh, ME region becomming India region when the page is refreshed.
  // if(regionInitStatus && region && currentURLRegion && region !== currentURLRegion){
  //   console.log('Setting greytHR Site Region to ',currentURLRegion)
  //   dispatch(setRegion(currentURLRegion))
  //   return
  // }
  
  if (regionInitStatus !== null) {
    return
  }
  dispatch(setRegionInitState('INITIALIZING'))

  if (region === undefined) {
    dispatch(getIpRegion())
  } else {
    // repair wrong region code
    if (!isSupportedRegion(region)) {
      console.log(`Incorrect region code detected: ${region}`)
      region = getAlternateRegionCode(region)
      console.log(`Alternate region code: ${region}`)
    }
    console.log(`cookie region is ${region}`)
    dispatch(setDetectedRegion(detectedRegion))
    dispatch(setRegion(region))
    dispatch(setRegionInitState('DONE'))
  }
}

const Wrapper = ({ children }) => {
  const isBrowser = typeof window !== "undefined"
  if(isBrowser){
    setupRegion()
    pushVisitorType()
  }
  return <>{children}</>
}

// eslint-disable-next-line react/display-name
export const wrapRootElement = ({ element }) => (
  <CookiesProvider>
    <Provider store={initStore()}>
      <Wrapper>{element}</Wrapper>
    </Provider>
  </CookiesProvider>
)
